@font-face {
    font-family: 'LogoFont';
    src: url('../assets/fonts/Heavitas.ttf') format('truetype');
}

@font-face {
    font-family: 'LogoSubFont';
    src: url('../assets/fonts/coolvetica\ rg.otf') format('opentype');
}

@font-face {
    font-family: 'HeaderFont';
    /* src: url('../assets/fonts/Nexa-Heavy.ttf') format('truetype'); */
    src: url('../assets/fonts/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'BodyText';
    src: url('../assets/fonts/MADE\ TOMMY\ Regular_PERSONAL\ USE.ttf') format('truetype');
}
